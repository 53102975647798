<template>
  <div>
    <div class="flex flex-wrap justify-center">Sell Rate</div>
    <div class="flex flex-wrap justify-center">
      <vs-input class="w-full mt-1" type="number" size="small" step="0.0001" placeholder="Markup" v-model.number="markup" />
      <vs-button class="mt-2 w-full" size="small" color="primary" @click="onApply" type="border">Apply</vs-button>      
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
  export default Vue.extend({
    components: { },
    props: [],
    data() {
      return {
        markup: null
      }
    },
    beforeMount() {
    },
    computed: {
    },
    methods: {
      onApply () {
        this.params.context.componentParent.addSellMarkup(this.params.companyId, this.markup)
      },
    }
  })
</script>