<template>
  <div>
    <div class="flex flex-wrap justify-center">API Rate</div>
    <div class="flex flex-wrap justify-center">
      <vs-button class="mt-2 w-full" size="small" color="primary" @click="onUpdate" type="border">Retrieve Cur. Rates</vs-button>      
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
  export default Vue.extend({
    components: { },
    props: [],
    data() {
      return {}
    },
    beforeMount() {
    },
    computed: {
    },
    methods: {
      onUpdate () {
        this.params.context.componentParent.getCurrentRates()
      },
    }
  })
</script>